<template>
  <roles-table
    :headers="headers"
    :roles="roles"
    @deleteRole="deleteRole($event)"
  />
</template>

<script>
import RolesTable from "../../../components/Tables/RolesTable.vue";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
        width: "150px",
      },
      {
        text: "Permissions",
        value: "permissions",
        width: "130px",
      },
      {
        value: "actions",
        width: "130px",
        align: "end",
      },
    ],
  }),
  async mounted() {
    //Roles
    await this.fetchRoles();
  },

  computed: {
    roles() {
      return this.$store.state.roles;
    },
  },
  components: {
    RolesTable,
  },
  methods: {
    async deleteRole(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("deleteRole", id);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "Role deleted successfully!",
          group: "success",
        });

        await this.fetchRoles();
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async fetchRoles() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readRoles");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>