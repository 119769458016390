<template>
  <v-card id="roles">
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            rounded
            dense
            height="1"
            color="#3371E2"
          ></v-text-field>
        </v-col>
        <v-col cols="9">
          <span class="float-right">
            <v-btn
              small
              depressed
              outlined
              color="#8cc73e"
              class="custom-transform-class"
              to="/users-management/add-role"
              >Add Role</v-btn
            >
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="roles"
      :search="search"
      hide-default-footer
      disable-pagination
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <router-link
          :to="{
            name: '',
            params: {
              id: item.id,
            },
          }"
        >
          <h5 class="mt-4">{{ item.name }}</h5>
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.permissions="{ item }">
        <p v-if="item.name=='SuperAdmin'"> All Permissions</p>
        <li v-for="item in item.permissions" :key="item.id">
          {{ item.name }}
        </li>
        <!-- <h5 class="mt-4">{{ item.permissions }}</h5> -->
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-btn v-if="item.name!=='SuperAdmin'" small class="mr-5" color="blue" icon @click="editRole(item.id)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="item.name!=='SuperAdmin'" small color="red" icon @click="deleteRole(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    search: "",
  }),
  props: {
    headers: Array,
    roles: Array,
  },
  methods: {
    editRole(id) {
        this.$router.push({name: 'update-role', params: { id: id }})
    },
    deleteRole(id) {
        this.$emit('deleteRole', id);
    }
  }
};
</script>

<style scoped>
#roles {
  margin: 2.5rem 0;
}
</style>